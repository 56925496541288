@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Regular"),
    url("assets/fonts/Gilroy/Gilroy-Regular.otf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Gilroy";

  --primary: #eb7b00;
  --secondary: #241513;
  --tertiary: #36cea0;
  --dark: #212121;
  --darkBlue: #006088;
  --active: #36cea0;
  --paused: #ffca28;
  --archived: #cfd8dc;
  --success: #36cea0;
  --danger: #d54800;
  --tableHeader: #eceff1;
  --tableStripedRow: #f7f6f6;
  --tableRowHover: #e6f4fa;
  --gray: #b0bec5;
  --inputBorder: #e0e0e0;
  --graph1: #374375;
  --graph2: #656e94;
  --graph3: #969cb6;
  --buttonDefault: #f5f5f5;
  --grayText: #9e9e9e;
  --gray000: #fafafa;
  --gray100: #f0f0f0;
  --gray300: #d7d7d7;
  --gray400: #585858;
  --gray500: #6e6d72;
  --gray700: #383e3f;
  --primaryBorderColor: #c3d2e2;
  --link: #03a9f4;
  --yellow: #f7bf39;
  --mobile-width: 500px;
}
